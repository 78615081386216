import React, { useEffect, useState, useContext } from "react";
import ViewSDKClient from "../../ViewSDKClient";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import annotationTemplate from "../AITable/annotationsTemplate";
import { useParams } from "react-router-dom";
// import { PdfContext } from '../../PdfContext';


const RenderMenu = ({url}) => {
  const [annotations, setAnnotations] = useState([]);
  const [annotationsFetched, setAnnotationsFetched] = useState(false);
  const [indexPageMapping,setIndexPageMapping]=useState({});
  const { mains_answer_sheet_hash, user_id, pdf_name, _id } = useParams();
  // const { pdfName } = useContext(PdfContext);
  // const pdf_name = pdfName || "cb.pdf";
  useEffect(() => {
    const fetchAnnotations = async () => {
      try {
        const response = await axios.post(
          "https://collectorbabu.com/api/mains/mains_get_reviews_in_text",
          {
            user_id: user_id,
            mains_answer_sheet_hash: mains_answer_sheet_hash
          },
          {
            headers: {
              "Content-Type": "application/json",
              "accept": "application/json"
            }
          }
        );
  
        const annotationsResponse = response.data;
        const annotationsData = annotationsResponse.map((annotation, index) => ({
          page_number: annotation.page_number,
          id: annotation.annotation_id,
          annotations: annotation.annotations || "",
          annotation_details: annotation.annotation_details || ""
        }));
  
        const pageNumberAndIndex = {};
        annotationsData.forEach((anno, index) => {
          const pageNumber = anno.page_number;
          if (!pageNumberAndIndex[index]) {
            pageNumberAndIndex[index] = pageNumber;
          }
        });
        setIndexPageMapping(pageNumberAndIndex);
        const list_of_annotations = annotationsData.map((each, index) => {
          if (each.annotation_details === "") {
            const annotation = JSON.parse(JSON.stringify(annotationTemplate));
            annotation.id = each.id;
            annotation.bodyValue = each.annotations;
            annotation.target.selector.node.index = index;
            return annotation;
          } else {
            const annotation_details = each.annotation_details
            const annotation = JSON.parse(JSON.stringify(annotationTemplate));
            annotation.id = each.id;
            annotation.bodyValue = each.annotations;
            annotation.target = annotation_details; 
            return annotation;
          }
        });
        // list_of_annotations.pop();
        console.log(list_of_annotations);
        setAnnotations(list_of_annotations);
        setAnnotationsFetched(true);
      } catch (error) {
        console.error("Error fetching annotations:", error);
      }
    };
  
    fetchAnnotations();
  }, [url]);
  

  useEffect(() => {
    if (annotationsFetched) {
      const loadPDF = async () => {
        try {
          const eventOptions = {
            listenOn: [
              "ANNOTATION_UPDATED"
            ]
          }
          const viewSDKClient = new ViewSDKClient();
          await viewSDKClient.ready();
          viewSDKClient.previewFile("pdf-div", {
            defaultViewMode: "FULL_WIDTH",
            showAnnotationTools: true,
            showLeftHandPanel: true,
            showPageControls: true,
            showDownloadPDF: false,
            showPrintPDF: false,
            enableAnnotationAPIs: true,
            includePDFAnnotations: true,
            enableAnnotationEvents: true,
            downloadWithCredentials: true,
          }, url, pdf_name).then(adobeViewer => {
            adobeViewer.getAnnotationManager().then(annotationManager => {
              annotationManager.addAnnotationsInPDF(annotations)
                .then(result => console.log("result"))
                .catch(error => console.log(error));
                annotationManager.registerEventListener(event => {
                  switch (event.type) {
                    case "ANNOTATION_UPDATED":
                      const editedAnnotation = event.data;
                      const updatedAnnotations = annotations.map(annotation => {
                        if (annotation.id === editedAnnotation.id) {
                          return editedAnnotation;
                        } else {
                          return annotation;
                        }
                      });
                      setAnnotations(updatedAnnotations);
                      handleSave([editedAnnotation]); 
                      break;
                    
                    case "ANNOTATION_ADDED":
                      const addedAnnotation = event.data;
                      if (event.data.target.selector.subtype==="shape"){
                        handleDrawSave(addedAnnotation);
                      }
                      // const newAnnotations = [...annotations, addedAnnotation];
                      // setAnnotations(newAnnotations);
                      break;
                  
                    default:
                      // Handle other event types if needed
                      break;
                  }
                  
                });
                
            },eventOptions).catch(error => console.log(error));
          });
        } catch (error) {
          console.log(error);
        }
      };

      loadPDF();
    }
  }, [annotationsFetched, url]);

  const extractAnnotations = (annotationsData) => {
    const extractedAnnotations = annotationsData.map(annotation => ({
      annotation_id: annotation.id,
      annotations: annotation.bodyValue,
      page_number:annotation.target.selector.node.index,
      // annotation_details:"text"
    }));

    return extractedAnnotations;
  };

  const replacedIndexWithPageNumber=(annotations)=>{
    const finalAnnotations=annotations.map((each)=>{
      each.page_number=indexPageMapping[each.page_number]
      return each
    })
    return finalAnnotations
  }

  const handleSave = async (annotations) => {
    try {
      const finalTestedAnnotations = await extractAnnotations(annotations);
      const finalWithCorrectPageNumber = await replacedIndexWithPageNumber(finalTestedAnnotations); 
      console.log("beforePageNumber",finalWithCorrectPageNumber);

      const response = await axios.post(
        "https://collectorbabu.com/api/mains/mains_save_edited_reviews",
        {
          user_id: user_id,
          _id:_id,
          mains_answer_sheet_hash: mains_answer_sheet_hash,
          edited_reviews: finalWithCorrectPageNumber,
        }
      );

      fetchUpdatedAnnotations();
    } catch (error) {
      console.error("Error saving edited reviews:", error);
    }
  };

  const extractDrawAnnotations = (annotationsDrawData) => {
  
    const { id, bodyValue, target } = annotationsDrawData;
    const { selector } = target;
    const { node } = selector;
    const { index } = node;
  
    const extractedAnnotations = [{
      annotation_id: id,
      annotations: bodyValue,
      page_number: index,
      // annotation_details: target
    }];
  
    return extractedAnnotations;
  };
  



  const handleDrawSave = async (annotations) => {
    try {
      const finalTestedAnnotations = await extractDrawAnnotations(annotations);
      const finalWithCorrectPageNumber = await replacedIndexWithPageNumber(finalTestedAnnotations); 

      console.log("finalWithCorrectPageNumber",finalWithCorrectPageNumber.length);

      const response = await axios.post(
        "https://collectorbabu.com/api/mains/mains_save_edited_reviews",
        {
          user_id: user_id,
          _id:_id,
          mains_answer_sheet_hash: mains_answer_sheet_hash,
          edited_reviews: finalWithCorrectPageNumber,
        }
      );

      fetchUpdatedAnnotations();
    } catch (error) {
      console.error("Error saving edited reviews:", error);
    }
  };

  const fetchUpdatedAnnotations = async () => {
    try {
      const response = await axios.post(
        "https://collectorbabu.com/api/mains/mains_get_reviews_in_text",
        {
          user_id: user_id,
          mains_answer_sheet_hash: mains_answer_sheet_hash
        },
        {
          headers: {
            "Content-Type": "application/json",
            "accept": "application/json"
          }
        }
      );
      const annotationsResponse = response.data;
      const annotationsData = annotationsResponse.map(annotation => ({
        page_number: annotation.page_number,
        annotations: annotation.annotations || ""
      }));
      const updatedAnnotations = annotationsData.map((each, index) => {
        const annotation = JSON.parse(JSON.stringify(annotationTemplate));
        annotation.id = `${uuidv4()}-${Date.now()}`;
        annotation.bodyValue = each.annotations;
        annotation.target.selector.node.index = index;
        return annotation;
      });

      setAnnotations(updatedAnnotations);
    } catch (error) {
      console.error("Error fetching updated annotations:", error);
    }
  };

  return (
    <div
      style={{ height: "100vh" }}
      id="pdf-div"
      className="full-window-div border border-gray-100 h-screen"
    ></div>
  );
};

export default RenderMenu;
